<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: '',
	
	created() {},
	mounted() {
	},
	data() {
		return {
			sortType: '不限',
			setMenuType: '全部套餐',
		}
	},
	methods: {
		sortTypeFn(item) {
			this.sortType = item
		},
		SetMenuTab(item) {
			this.setMenuType = item
		},
	},
}
</script>

<style lang=scss scoped>
@import '../../scss/SetMenu.scss';
</style>